<template>
  <div class="card pt-5 pb-5">
    <validation-observer ref="changePassword">
      <b-form class="row">
        <div class="col-md-10 col-sm-12 m-auto">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-1">
                {{ $t('Change your email') }}
              </h3>
            </div>
          </div>
          <div v-if="!successStep" class="">
            <div class="row">
              <div class="col-6">
                <b-form-group label-for="old_email" :label="$t('Old email')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Old email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="old_email"
                      v-model="formData.old_email"
                      readonly
                      :state="errors.length > 0 ? false : null"
                      placeholder="Stephanie@toddl.co"
                    />
                    <p class="text-danger">
                      {{ errors[0] }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-form-group label-for="new_email" :label="$t('New email')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('New email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="new_email"
                      v-model="formData.new_email"
                      :state="errors.length > 0 || sameEmailError ? false : null"
                      placeholder="Stephanie@toddl.co"
                    />
                    <p class="text-danger">
                      {{ errors[0] }}
                    </p>
                    <p class="text-danger">
                      {{ sameEmailError }}
                    </p>
                    <p class="text-danger">
                      {{ wrongPassword }}
                    </p>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-button
                  class="mr-2 footer-btn"
                  variant="primary"
                  type="submit"
                  :disabled="submitLoader"
                  @click.prevent="validationForm()"
                >
                  {{ $t('Save Changes') }}
                </b-button>

                <b-button
                  class="footer-btn"
                  :to="{ name: 'edit-my-profile' }"
                  type="submit"
                  variant="outline-primary"
                  :class="formData.published === 0 ? 'ml-2' : ''"
                  :disabled="submitLoader"
                  @click.prevent
                >
                  {{ $t('Back') }}
                </b-button>
              </div>
            </div>
          </div>
          <div v-if="successStep">
            <p class="mb-1">
              {{ $t("We've sent you a verification email, please check your old email!") }}
            </p>
            <b-button
              class="mr-2 mt-1 footer-btn"
              variant="primary"
              type="submit"
              :to="{ name: 'edit-my-profile' }"
              :disabled="submitLoader"
              @click.prevent="validationForm()"
            >
              {{ $t('Back to my profile') }}
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations'
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@/mixins/togglePasswordVisibility'
import MyProfileService from '@/services/MyProfileService'

export default {
  name: 'ChangePassword',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      MyProfileService: new MyProfileService(),
      submitLoader: false,
      formData: {
        old_email: this.$store.state.auth.user.email,
        new_email: '',
      },
      required,
      wrongPassword: '',
      sameEmailError: undefined,
      successStep: false,
    }
  },
  watch: {
    'formData.new_email'(newVal) {
      this.wrongPassword = undefined
      this.sameEmailError = undefined
      if (newVal === this.formData.old_email) {
        this.sameEmailError = this.$t('New email should be different form current email')
      }
    },
  },
  methods: {
    validationForm() {
      if (this.sameEmailError) return
      this.$refs.changePassword.validate().then(success => {
        if (success) {
          this.submitLoader = true
          this.wrongPassword = ''
          this.MyProfileService.changeEmail(this.formData).then(
            res => {
              this.submitLoader = false
              this.successStep = true
              // this.$router.push({name: 'edit-my-profile'})
            },
            error => {
              console.log(error)
              this.submitLoader = false
              if (error.status_code === 422) {
                this.wrongPassword = error.errors[0][0]
              }
            }
          )
        }
      })
    },
  },
}
</script>

<style scoped></style>
